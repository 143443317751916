<template>
    <div>
        <div class="nullimg">
            <img src="../../assets/homePage/1662105414657.png" alt="">
        </div>
    </div>
</template>

<script>
export default {
    name: "mask_Component"
}
</script>

<style lang="scss" scoped>
.nullimg {
    display: flex;
    width: 1200px;
    height: 335px;
    background: #FFFFFF;
    border-radius: 16px 16px 16px 16px;
    margin: 24px auto 0;

    img {
        width: 590px;
        height: 299px;
        margin: 18px auto;
    }
}
</style>