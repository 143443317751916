<template>
    <div>


        <div class="logistics">
            <!-- <div class="img">
                <img src="../../assets/首页/首页/1662105414657.png" alt="">
            </div> -->
            <!-- 基本信息 -->
            <div class="essentialInformation">
                <div class="basic_Title">基本信息</div>
                <div class="basic_Content">
                    <div class="content_Left">
                        <ul>
                            <li>
                                <p>订舱号 ：</p>
                                <span>{{ essentialInformation.orderNo }} </span>
                            </li>
                            <li>
                                <p>接货地 ：</p>
                                <span>{{ essentialInformation.departure }}</span>
                            </li>
                            <li>
                                <p>装箱时间 ：</p>
                                <span v-if="essentialInformation.pickUpTime">
                                    {{ essentialInformation.pickUpTime.split(' ')[0] || "-" }}
                                </span>
                                <span v-else>-</span>
                            </li>
                            <!-- <li>
                                <p>装货港 ：</p>
                                <span>Ningbo-Meishan-Island Int'l Container Tml</span>
                            </li>

                            <li>
                                <p>船名 / 航次 ：</p>
                                <span>CMA CGM JACQUES SAADE / 0FLCNW1MA</span>
                            </li>
                            <li>
                                <p>箱型尺寸数量：</p>
                                <span>20GP*1</span>
                            </li>
                            <li>
                                <p>EIR号 ：</p>
                                <span>NGB016366433/20GP</span>
                            </li> -->
                        </ul>
                    </div>
                    <div class="content_Right">
                        <ul>
                            <li>
                                <p>提单号 ：</p>
                                <span>{{ essentialInformation.billNo }}</span>
                            </li>
                            <li>
                                <p>目的地 ：</p>
                                <span>{{ essentialInformation.destination }}</span>
                            </li>
                            <li>
                                <div v-if="essentialInformation.arrivalTime" style="display: flex;">
                                    <p>已到港： </p>
                                    <span v-if="essentialInformation.arrivalTime"> {{
                                            essentialInformation.arrivalTime.split(' ')[0] || '-'
                                    }}</span>
                                    <p v-else>-</p>
                                </div>
                                <div v-else-if="essentialInformation.expectArrivalTime" style="display: flex;">
                                    <p>预计到达时间： </p>
                                    <span v-if="essentialInformation.expectArrivalTime"> {{
                                            essentialInformation.expectArrivalTime.split(' ')[0] || '-'
                                    }}</span>
                                    <p v-else>-</p>
                                </div>
                            </li>
                            <!-- <li>
                                <p>卸货港 ：</p>
                                <span>Stockholm-Port of Stockholm</span>
                            </li>

                            <li>
                                <p>预计到达提货地时间：</p>
                                <span>CMA CGM JACQUES SAADE / 0FLCNW1MA</span>
                            </li>
                            <li>
                                <p>箱型尺寸数量：</p>
                                <span>20GP*1</span>
                            </li> -->
                        </ul>
                    </div>
                </div>
            </div>
            <!-- 物流信息 -->
            <!-- <div class="logisticsInformation">
                    <div class="logistics_Title">物流信息</div>
                    <div class="mappings">
                        <div class="mappings_Box"
                            :class="{ 'activeRight': activeR, 'leftArrows': leftArrowL, 'rightArrows': rightArrowR }">
                            <div class="steps1">
                                <el-steps :active="left" align-center style="width:100%;" space="280px">
                                    <div class="img" :style="{ left: `calc(217px*${left}) ` }">
                                        <img src="../../assets/ship/ship.png" alt="" />
                                    </div>
                                    <el-step :title="item.description" v-for="(item, index) in cargoTrackingList"
                                        :key="index" :description="item.nextShip">
                                        <i class="build stepIcon" slot="icon"></i>
                                    </el-step>
                                   
                                </el-steps>
                            </div>

                            <ul>
                                <li v-for="(item, index) in cargoTrackingList" :key="index">
                                    <p>{{ item.description }}</p>
                                    <p>{{ item.renewTime }}</p>
                                </li>
                                
                            </ul>
                        </div>
                        <div class="leftArrow" @click="leftArrow"><img src="../../assets/homePage/left.png" alt="">
                        </div>
                        <div class="rightArrow" @click="rightArrow"><img src="../../assets/homePage/right.png" alt="">
                        </div>
                    </div>
                </div> -->

            <div class="ProgressBoxTool">
                <div class="logistics_Title">物流信息</div>
                <div class="processBox">
                    <div class="imgsa" :class="currentClickNumber > 0 ? 'arrow' : 'arrow arrowOpacity'"
                        @click="fnPrev()">
                        <img :src="arrowL" alt="" />
                    </div>
                    <div class="fixedBox" :ref="(`fixedBox`)">
                        <div class="centerScroll" :style="`width:${signleWidth * cargoTrackingLists.length
                        }px;transform:translate(${scrollResultWidth}px,0);transition:1s;`">

                            <div class="signleTab">
                                <div class="leftIcon">
                                    <img v-if="(cargoTrackingLists.length == 0)" class="pregressIcon" :src="icon"
                                        alt="" />
                                    <img v-else class="pregressIcon" :src="ico3" alt="" />
                                </div>
                                <p class="singTitle1">发货地</p>
                                <p class="singTitle2">{{ essentialInformation.departure }}</p>
                                <!-- 最后一个不展示箭头 -->
                                <div v-if="(cargoTrackingLists.length == 0)" class="arrowSquare"><span></span></div>
                                <div v-else class="arrowSquare1"><span></span></div>

                                <div class="singCon">
                                    <p>预计发货时间 </p>
                                    <p v-if="essentialInformation.createTime">
                                        {{ essentialInformation.createTime.split(" ")[0] }}
                                    </p>
                                    <p v-else>-</p>
                                </div>

                            </div>

                            <div class="signleTab lisImg" v-for="(itemP, indexP) in cargoTrackingLists"
                                :key="indexP + 'progress'">
                                <template>
                                    <div class="newImg" v-show="(indexP == cargoTrackingLists.length - 1)">
                                        <img src="../../assets/ship/da2b74cb9bfda82ba01f12eea53a68e.png" alt="">
                                    </div>
                                    <div class="leftIcon">
                                        <img v-if="(indexP == cargoTrackingLists.length - 1)" class="pregressIcon"
                                            :src="icon1" alt="">
                                        <img v-else class="pregressIcon" :src="icon2" alt="" />
                                    </div>
                                    <p class="singTitle1">{{ itemP.description || '-' }}</p>
                                    <!-- <p class="singTitle2">{{ itemP.nextShip || '-' }}</p> -->
                                    <!-- 最后一个不展示箭头 -->
                                    <!-- <p ><span></span></p> -->
                                    <div v-if="cargoTrackingLists.length > indexP" class="arrowSquare"
                                        :class="{ 'activearr': indexP == cargoTrackingLists.length - 1, 'activearr1': cargoTrackingLists[cargoTrackingListLength].nextShip == null && indexP == cargoTrackingList.length - 1 }">
                                        <span></span>
                                        <div class="shipImg">
                                            <img v-if="((itemP.nextShip !== null) && (indexP == cargoTrackingLists.length - 1) && (essentialInformation.orderType == '海运订单'))"
                                                src="../../assets/ship/ship.png" alt="">

                                            <img src="../../assets/ship/feiji.png" alt=""
                                                v-if="((itemP.nextShip !== null) && (indexP == cargoTrackingLists.length - 1) && (essentialInformation.orderType == '空运订单'))">

                                            <img src="../../assets/ship/subbay.png" alt=""
                                                v-if="((itemP.nextShip !== null) && (indexP == cargoTrackingLists.length - 1) && (essentialInformation.orderType == '铁路订单'))">

                                        </div>
                                        <div class="textNull"
                                            v-if="(itemP.nextShip !== null) && (indexP == cargoTrackingLists.length - 1)">
                                            <!-- <p>门点地址</p>
												<p>{{ itemP.nextShip }}</p> -->
                                            <div class="singCon" v-if="itemP.arrivalTime" style="margin-top: 0;">
                                                <!-- <span style="font-size: 10px;">预计到达时间:
														{{ itemP.arrivalTime.split(" ")[0] }}</span> -->
                                                <p style="font-size: 10px;">预计到达时间:
                                                    {{ itemP.arrivalTime.split(" ")[0] }}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <p class="singTitle2" v-if="itemP.renewTime"> &nbsp;</p>
                                    <p v-else>-</p>

                                    <div class="singCon">
                                        <p>更新时间 </p>
                                        <p v-if="itemP.renewTime"> {{ itemP.renewTime.split(" ")[0] }}</p>
                                    </div>
                                </template>
                                <template v-if="(indexP == cargoTrackingLists.length - 1)">

                                </template>

                            </div>
                            <div class="signleTab "
                                v-if="(cargoTrackingLists[cargoTrackingListLength].nextShip !== null)">
                                <div class="newimg"></div>
                                <div style="display: flex;">
                                    <!-- <div class="arrowSquares"></div> -->
                                    <div class="leftIcon">
                                        <img class="pregressIcon" :src="xiehuo" alt="" />
                                    </div>
                                </div>

                                <p class="singTitle1">下一港</p>
                                <p class="singTitle2">{{ cargoTrackingLists[cargoTrackingListLength].nextShip }}</p>
                                <!-- <div class="arrowSquarea"><span></span></div> -->
                                <!-- 最后一个不展示箭头 -->

                                <!-- <div class="singCon">
										<div v-if="essentialInformation.arrivalTime">
											<p>已到港 </p>
											<p v-if="essentialInformation.arrivalTime"> {{
													essentialInformation.arrivalTime.split(' ')[0] || '-'
											}}</p>
											<p v-else>-</p>
										</div>
										<div v-else-if="essentialInformation.expectArrivalTime">
											<p>预计到达时间 </p>
											<p v-if="essentialInformation.expectArrivalTime"> {{
													essentialInformation.expectArrivalTime.split(' ')[0] || '-'
											}}</p>
											<p v-else>-</p>
										</div>
									</div> -->

                            </div>

                            <!-- <div class="signleTab">
									<div class="newimg"></div>
									<div style="display: flex;">
										
										<div class="leftIcon">
											<img class="pregressIcon" :src="xiehuo" alt="" />
										</div>
									</div>

									<p class="singTitle1">卸货地</p>
									<p class="singTitle2">{{ essentialInformation.destination }}</p>

									

									<div class="singCon">
										
										<div v-if="essentialInformation.arrivalTime">
											<p>已到港 </p>
											<p v-if="essentialInformation.arrivalTime"> {{
													essentialInformation.arrivalTime.split(' ')[0] || '-'
											}}</p>
											<p v-else>-</p>
										</div>
										<div v-else-if="essentialInformation.expectArrivalTime">
											<p>预计到达时间 </p>
											<p v-if="essentialInformation.expectArrivalTime"> {{
													essentialInformation.expectArrivalTime.split(' ')[0] || '-'
											}}</p>
											<p v-else>-</p>
										</div>
									</div>
								</div> -->


                        </div>
                    </div>

                    <div class="imgsa" :class="noScrollRight ? 'arrow' : 'arrow arrowOpacity'"
                        @click="fnNext(activeName)">
                        <img :src="arrowR" alt="" />
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import icon from "../../assets/homePage/placeOfShipment.png";
import ico3 from "../../assets/homePage/placeOfShipment1.png";
import arrowL from "../../assets/homePage/leftArrow.png";
import arrowR from "../../assets/homePage/rightArrow.png";
import icon1 from "../../assets/homePage/blueqizi.png";
import icon2 from "../../assets/homePage/huiqizi.png";
import xiehuo from '../../assets/homePage/xiehuo.png';
import iconArrow from "../../assets/homePage/departureAndArrival.png";
export default {
    name: "search_Component1",
    props: ['essentialInformation', 'cargoTrackingList'],
    data() {
        return {
            flags: true,

            bookingNo: "",
            value: "",
            cargoTrackingLists: this.cargoTrackingList, //货运追踪
            cargoTrackingListLength: '',
            essentialInformations: this.essentialInformation, //基本信息
            stepIndex: 1,
            left: 5,
            leftArrowL: false,
            rightArrowR: false,
            activeR: false,

            arrowL,
            arrowR,
            icon,
            ico3,
            icon1,
            icon2,
            iconArrow,
            xiehuo,
            currentProgressId: "",
            scrollResultWidth: 0, //transform滚动的距离
            signleWidth: 300, //单个流程的宽度
            activeName: 0,
            currentClickNumber: 0,
            noScrollRight: true,
        }
    },
    mounted() {

        console.log(this.essentialInformation);
        console.log(this.cargoTrackingList);
        this.cargoTrackingLists = this.cargoTrackingList
    },
    methods: {
        serverBtn() {

            if (this.bookingNo == '') {
                this.$message({
                    showClose: true,
                    message: '请输入订舱号',
                    type: 'warning'
                });
                return false
            } else {
                var token = sessionStorage.getItem("token");
                axios.get(`${baseUrl}/routering/search`, {
                    params: {
                        keyWords: this.bookingNo, //bookingNo 92022080910385657053
                    },
                    headers: {
                        'authorization': token
                    }
                })
                    .then(res => {
                        if (res.data.success == true) {
                            this.frequency++;
                            this.cargoTrackingListLength = '';
                            this.cargoTrackingList = [];
                            console.log(res.data.result);
                            this.essentialInformation = res.data.result.oderDetail;
                            this.cargoTrackingList = res.data.result.routering;
                            this.cargoTrackingList = this.cargoTrackingList.reverse()

                            // let height = this.$refs.scrollRef.clientWidth;
                            //判断内容超过一屏时：
                            // this.flags = false;
                            this.$nextTick(() => {
                                setTimeout(() => {
                                    this.initgoRightArrow();
                                    console.log("%");
                                });
                            });
                            var ri = 0;
                            ri = this.cargoTrackingList.length;

                            this.cargoTrackingListLength = ri - 1;
                            console.log(ri);
                            // for (let i = 0; i <= this.cargoTrackingList.length; i++) {
                            // 	console.log("!");
                            // 	console.log(this.fnNext());
                            // }
                            clearTimeout(this.timer)
                            this.timer = null;
                            if (this.frequency <= 1) {

                                this.timer = setTimeout(() => {
                                    // let height = this.$refs[`fixedBox`].scrollWidth
                                    // this.$refs[`fixedBox`].scrollTo(height, 0);
                                    // console.log(height);
                                    for (let i = 0; i <= ri; i++) {
                                        this.fnNext();
                                        console.log("i");
                                    }
                                }, 1)
                            }



                        } else {
                            let messages = res.data.message;
                            this.$message({
                                showClose: true,
                                message: messages,
                                type: 'warning'
                            });
                            return false
                        }
                    })
                    .catch(err => {
                        console.error(err);
                    })
            }

        },
        leftArrow() {
            // console.log("右滑动");

            // this.activeR = false;
            this.leftArrowL = true;
            this.rightArrowR = false


        },
        rightArrow() {
            this.rightArrowR = true;
            this.leftArrowL = false;
        },


        // ----------------
        //初始化判断是否可以向右滚动
        initgoRightArrow() {
            const currentScrollWidth = this.$refs[`fixedBox`].clientWidth || null;
            const canNumber = Math.floor(currentScrollWidth / this.signleWidth); //可以放下的个数
            // console.log(canNumber);
            //如果最后一个流程图标已经展示出来，则停止滚动
            if (this.currentClickNumber + canNumber >= this.cargoTrackingList.length) {
                this.noScrollRight = false;
                return;
            }
        },
        //点击上一个
        fnPrev() {
            //如果右点击的次数大于0，才可以左滚
            if (this.currentClickNumber > 0) {
                this.currentClickNumber -= 1;
                this.noScrollRight = true;
                this.fnScrollWidth("reduce");
            } else {
                return false;
            }
        },
        //点击下一个
        fnNext() {
            const currentScrollWidth = this.$refs[`fixedBox`].clientWidth || null;
            const canNumber = Math.floor(currentScrollWidth / this.signleWidth); //可以放下的个数
            //如果最后一个流程图标已经展示出来，则停止滚动
            if (this.currentClickNumber + canNumber >= this.cargoTrackingList.length) {
                return;
            }
            //说明放不下有滚动条
            if (this.cargoTrackingList.length > canNumber) {
                this.currentClickNumber += 1;
                if (this.currentClickNumber + canNumber >= this.cargoTrackingList.length) {
                    this.noScrollRight = false;
                }
                this.fnScrollWidth("add");
            }
        },
        //translate的宽度
        fnScrollWidth(type) {
            let result = 0;
            if (type === "reduce") {
                result = 300;
            } else if (type === "add") {
                result = -300;
            } else {
                result = 0;
            }
            this.scrollResultWidth += result;
        },
    },
}
</script>

<style lang="scss" scoped>
.logistics {
    width: 1200px;
    height: 660px;
    // border-radius: 16px 16px 16px 16px;
    border: 1px solid #999999;
    margin-top: 24px;
    background: #FFFFFF;

    .img {
        width: 590px;
        height: 299px;
        margin: 0 auto;
    }

    // 基本信息
    .essentialInformation {
        width: 1152px;


        margin: 32px auto 0;

        .basic_Title {
            width: 64px;
            height: 16px;
            font-size: 16px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #999999;
            line-height: 16px;
        }

        .basic_Content {
            position: relative;
            width: 1152px;
            display: flex;
            justify-content: space-around;
            // height: 218px;
            background: #F4F4F4;
            margin-top: 17px;
            font-size: 12px;
            // padding-top: 32px;
            padding-bottom: 32px;

            ul {
                margin-top: 30px;

                li {
                    display: flex;
                    margin-top: 14px;
                    font-weight: 400;
                    font-size: 12px;

                    p {
                        text-align: right;
                        width: 123px;
                        height: 12px;

                        color: #999999;
                    }

                    span {

                        color: #333333;
                    }
                }

                li:nth-child(1) {
                    margin-top: 0;
                }
            }
        }

        .basic_Content::before {
            position: absolute;
            bottom: -24px;
            content: '';
            width: 1152px;
            height: 0px;
            border-bottom: 1px dashed #999999;
        }
    }

    .logisticsInformation {
        margin-top: 56px;
        padding: 0 24px;

        .logistics_Title {
            font-size: 16px;
            font-weight: 400;
            color: #999999;
            line-height: 16px;
        }


        .mappings {
            position: relative;
            padding-top: 50px;
            white-space: nowrap;
            width: 100%;

            // overflow: hidden;

            // overflow-x: scroll;
            .mappings_Box {
                // position: absolute;
                position: relative;
                width: 4000px;
                // width: auto;
                margin-left: 40px;
                // transform: translateX(-200px);

                .img {
                    position: absolute;
                    top: -20px;
                    // left: 220px;
                    width: 24px;
                    height: 18px;
                    z-index: 100;
                }

                /*完成状态——横线颜色设置*/
                ::v-deep .is-finish .el-step__line {
                    background-color: #025291;
                }

                /*完成状态——圆圈边框颜色*/
                ::v-deep .el-step__head.is-finish {
                    border-color: transparent;
                }

                ::v-deep .el-step__line {
                    border-color: transparent;
                }

                /*等待状态——序号颜色、圆圈边框颜色*/
                ::v-deep .el-step__head.is-process {
                    border-color: transparent;
                }

                ::v-deep .el-step__head.is-wait {
                    border-color: transparent;
                }

                ::v-deep .el-step__icon {
                    width: 40px;
                    height: 40px;
                }

                ::v-deep .el-step.is-horizontal .el-step__line {
                    top: 18px;
                }

                ::v-deep .el-step__icon {
                    // background: url('../../assets/订舱-立即下单/校验_check-one\ \(1\).png') no-repeat;
                    background-size: cover;
                    background-position: 0 -7px;
                }

                ::v-deep .el-step__title {
                    color: #999999;
                    font-size: 14px;
                    font-weight: 500;
                }

                ::v-deep .el-step__description {
                    color: #333333;
                    font-size: 12px;
                }

                ul {
                    display: flex;
                    justify-content: space-around;
                    text-align: center;
                    margin-top: 24px;

                    li {
                        margin-top: 8px;
                        font-size: 14px;
                        font-weight: 500;
                        color: #025291;
                    }
                }
            }

            .leftArrow,
            .rightArrow {
                position: absolute;
                // top: 0;
                width: 28px;
                height: 28px;
                // background: #F4F4F4;
                // background: red;
                background: #F4F4F4;
                border-radius: 30px 30px 30px 30px;

                img {
                    // display: block;
                    // margin: auto;
                    width: 18px;
                    height: 19px;
                    transform: translate(25%, 25%)
                }
            }

            .leftArrow {
                top: 50%;
                // background: url('../../assets/homePage/left.png') no-repeat;
                background: #F4F4F4;
            }

            .rightArrow {
                top: 50%;
                right: 0;
            }
        }


    }
}
</style>