<template>
	<div class="box1">
		<div class="server_box">
			<div class="servers" ref="boxs">
				<div class="server_Title">
					<img src="../../assets/homePage/Cargo tracking.png">
					<span>货物追踪</span>
				</div>
				<div class="server_Button">
					<!-- <div class="setOut">
                        <el-select v-model="value" placeholder="请选择">
                            <el-option value="订单号">订单号</el-option>
                            <el-option value="提单号">提单号</el-option>
                            <el-option value="发票号">发票号</el-option>
                            <el-option value="装箱号">装箱号</el-option>
                            <el-option value="合同号">合同号</el-option>
                            <el-option value="铅封号">铅封号</el-option>
                        </el-select>
                    </div> -->
					<div class="bookingNo">
						<input v-model="bookingNo" placeholder="请输入订单号、提单号、发票号，装箱号、合同号、铅封号进行查询！！！"
							:change="check_num()" />
					</div>
					<div class="btn" @click="serverBtn">
						<!-- <span class=""></span> -->
						<el-button icon="el-icon-search">搜索</el-button>
					</div>
				</div>
			</div>
			<mask_Component v-if="changeflag"></mask_Component>
			<!-- <search_Component v-else :essentialInformation="essentialInformation"
				:cargoTrackingList="cargoTrackingList">
			</search_Component> -->
			<div class="logistics" v-if="!changeflag">
				<!-- <div class="img">
                <img src="../../assets/首页/首页/1662105414657.png" alt="">
            </div> -->
				<!-- 基本信息 -->
				<div class="essentialInformation">
					<div class="basic_Title">基本信息</div>
					<div class="basic_Content">
						<div class="content_Left">
							<ul>
								<li>
									<p>订舱号 ：</p>
									<span>{{ essentialInformation.orderNo }} </span>
								</li>
								<li>
									<p>接货地 ：</p>
									<span>{{ essentialInformation.departure }}</span>
								</li>
								<li>
									<p>装箱时间 ：</p>
									<span v-if="essentialInformation.pickUpTime">
										{{ essentialInformation.pickUpTime.split(' ')[0] || "-" }}
									</span>
									<span v-else>-</span>
								</li>
								<li v-if="essentialInformation.transName || essentialInformation.voyageNumber">
									<p>船名 / 航次 ：</p>
									<span>{{ essentialInformation.transName }} /
										{{ essentialInformation.voyageNumber
										}}</span>
								</li>
								<!-- <li>
                                <p>装货港 ：</p>
                                <span>Ningbo-Meishan-Island Int'l Container Tml</span>
                            </li>

                            <li>
                                <p>船名 / 航次 ：</p>
                                <span>CMA CGM JACQUES SAADE / 0FLCNW1MA</span>
                            </li>
                            <li>
                                <p>箱型尺寸数量：</p>
                                <span>20GP*1</span>
                            </li>
                            <li>
                                <p>EIR号 ：</p>
                                <span>NGB016366433/20GP</span>
                            </li> -->
							</ul>
						</div>
						<div class="content_Right">
							<ul>
								<li>
									<p>提单号 ：</p>
									<span>{{ essentialInformation.billNo }}</span>
								</li>
								<li>
									<p>目的地 ：</p>
									<span>{{ essentialInformation.destination }}</span>
								</li>
								<li>
									<div v-if="essentialInformation.arrivalTime" style="display: flex;">
										<p>已到港：</p>
										<span v-if="essentialInformation.arrivalTime"> {{
												essentialInformation.arrivalTime.split(' ')[0] || '-'
										}}</span>
										<p v-else>-</p>
									</div>
									<div v-else-if="essentialInformation.expectArrivalTime" style="display: flex;">
										<p>预计到达时间： </p>
										<span v-if="essentialInformation.expectArrivalTime"> {{
												essentialInformation.expectArrivalTime.split(' ')[0] || '-'
										}}</span>
										<p v-else>-</p>
									</div>
								</li>
								<!-- <li>
                                <p>卸货港 ：</p>
                                <span>Stockholm-Port of Stockholm</span>
                            </li>

                            <li>
                                <p>预计到达提货地时间：</p>
                                <span>CMA CGM JACQUES SAADE / 0FLCNW1MA</span>
                            </li>
                            <li>
                                <p>箱型尺寸数量：</p>
                                <span>20GP*1</span>
                            </li> -->
							</ul>
						</div>
					</div>
				</div>
				<!-- 物流信息 -->
				<!-- <div class="logisticsInformation">
                    <div class="logistics_Title">物流信息</div>
                    <div class="mappings">
                        <div class="mappings_Box"
                            :class="{ 'activeRight': activeR, 'leftArrows': leftArrowL, 'rightArrows': rightArrowR }">
                            <div class="steps1">
                                <el-steps :active="left" align-center style="width:100%;" space="280px">
                                    <div class="img" :style="{ left: `calc(217px*${left}) ` }">
                                        <img src="../../assets/ship/ship.png" alt="" />
                                    </div>
                                    <el-step :title="item.description" v-for="(item, index) in cargoTrackingList"
                                        :key="index" :description="item.nextShip">
                                        <i class="build stepIcon" slot="icon"></i>
                                    </el-step>
                                   
                                </el-steps>
                            </div>

                            <ul>
                                <li v-for="(item, index) in cargoTrackingList" :key="index">
                                    <p>{{ item.description }}</p>
                                    <p>{{ item.renewTime }}</p>
                                </li>
                                
                            </ul>
                        </div>
                        <div class="leftArrow" @click="leftArrow"><img src="../../assets/homePage/left.png" alt="">
                        </div>
                        <div class="rightArrow" @click="rightArrow"><img src="../../assets/homePage/right.png" alt="">
                        </div>
                    </div>
                </div> -->

				<div class="ProgressBoxTool">
					<div class="logistics_Title">物流信息</div>
					<div class="processBox">
						<div class="imgsa" :class="currentClickNumber > 0 ? 'arrow' : 'arrow arrowOpacity'"
							@click="fnPrev()">
							<img :src="arrowL" alt="" />
						</div>
						<div class="fixedBox" :ref="(`fixedBox`)">
							<div class="centerScroll" :ref="(`centerSc`)" :style="`width:${signleWidth * cargoTrackingList.length
							}px;transform:translate(${scrollResultWidth}px,0);transition:1s;`">

								<div class="signleTab">
									<div class="leftIcon">
										<img v-if="(cargoTrackingList.length == 0)" class="pregressIcon" :src="icon"
											alt="" />
										<img v-else class="pregressIcon" :src="ico3" alt="" />
									</div>
									<p class="singTitle1">发货地</p>
									<p class="singTitle2">{{ essentialInformation.departure }}</p>
									<!-- 最后一个不展示箭头 -->
									<div v-if="(cargoTrackingList.length == 0)" class="arrowSquare"><span></span></div>
									<div v-else class="arrowSquare1"><span></span></div>

									<div class="singCon">
										<p>预计发货时间 </p>
										<p v-if="essentialInformation.createTime">
											{{ essentialInformation.createTime.split(" ")[0] }}
										</p>
										<p v-else>-</p>
									</div>

								</div>

								<div class="signleTab lisImg" v-for="(itemP, indexP) in cargoTrackingList"
									:key="indexP + 'progress'">
									<template>
										<div class="newImg" v-show="(indexP == cargoTrackingList.length - 1)">
											<img src="../../assets/ship/da2b74cb9bfda82ba01f12eea53a68e.png" alt="">
										</div>
										<div class="leftIcon">
											<img v-if="(indexP == cargoTrackingList.length - 1)" class="pregressIcon"
												:src="icon1" alt="">
											<img v-else class="pregressIcon" :src="icon2" alt="" />
										</div>
										<p class="singTitle1">{{ itemP.description || '-' }}</p>
										<!-- <p class="singTitle2">{{ itemP.nextShip || '-' }}</p> -->
										<!-- 最后一个不展示箭头 -->
										<!-- <p ><span></span></p> -->
										<div v-if="cargoTrackingList.length > indexP" class="arrowSquare"
											:class="{ 'activearr': indexP == cargoTrackingList.length - 1, 'activearr1': cargoTrackingList[cargoTrackingListLength].nextShip == null && indexP == cargoTrackingList.length - 1 }">
											<span></span>
											<div class="shipImg">
												<img v-if="((itemP.nextShip !== null) && (indexP == cargoTrackingList.length - 1) && (essentialInformation.orderType == '海运订单'))"
													src="../../assets/ship/ship.png" alt="">

												<img src="../../assets/ship/feiji.png" alt=""
													v-if="((itemP.nextShip !== null) && (indexP == cargoTrackingList.length - 1) && (essentialInformation.orderType == '空运订单'))">

												<img src="../../assets/ship/subbay.png" alt=""
													v-if="((itemP.nextShip !== null) && (indexP == cargoTrackingList.length - 1) && (essentialInformation.orderType == '铁路订单'))">

											</div>
											<div class="textNull"
												v-if="(itemP.nextShip !== null) && (indexP == cargoTrackingList.length - 1)">
												<!-- <p>门点地址</p>
												<p>{{ itemP.nextShip }}</p> -->
												<div class="singCon" v-if="itemP.arrivalTime" style="margin-top: 0;">
													<!-- <span style="font-size: 10px;">预计到达时间:
														{{ itemP.arrivalTime.split(" ")[0] }}</span> -->
													<p style="font-size: 10px;">预计到达时间:
														{{ itemP.arrivalTime.split(" ")[0] }}</p>
												</div>
											</div>
										</div>

										<p class="singTitle2" v-if="itemP.renewTime"> &nbsp;</p>
										<p v-else>-</p>

										<div class="singCon">
											<p>更新时间 </p>
											<p v-if="itemP.renewTime"> {{ itemP.renewTime.split(" ")[0] }}</p>
										</div>
									</template>
									<template v-if="(indexP == cargoTrackingList.length - 1)">

									</template>

								</div>
								<div class="signleTab "
									v-if="(cargoTrackingList[cargoTrackingListLength].nextShip !== null)">
									<div class="newimg"></div>
									<div style="display: flex;">
										<!-- <div class="arrowSquares"></div> -->
										<div class="leftIcon">
											<img class="pregressIcon" :src="xiehuo" alt="" />
										</div>
									</div>

									<p class="singTitle1">下一港</p>
									<p class="singTitle2">{{ cargoTrackingList[cargoTrackingListLength].nextShip }}</p>
									<!-- <div class="arrowSquarea"><span></span></div> -->
									<!-- 最后一个不展示箭头 -->

									<!-- <div class="singCon">
										<div v-if="essentialInformation.arrivalTime">
											<p>已到港 </p>
											<p v-if="essentialInformation.arrivalTime"> {{
													essentialInformation.arrivalTime.split(' ')[0] || '-'
											}}</p>
											<p v-else>-</p>
										</div>
										<div v-else-if="essentialInformation.expectArrivalTime">
											<p>预计到达时间 </p>
											<p v-if="essentialInformation.expectArrivalTime"> {{
													essentialInformation.expectArrivalTime.split(' ')[0] || '-'
											}}</p>
											<p v-else>-</p>
										</div>
									</div> -->

								</div>

								<!-- <div class="signleTab">
									<div class="newimg"></div>
									<div style="display: flex;">
										
										<div class="leftIcon">
											<img class="pregressIcon" :src="xiehuo" alt="" />
										</div>
									</div>

									<p class="singTitle1">卸货地</p>
									<p class="singTitle2">{{ essentialInformation.destination }}</p>

									

									<div class="singCon">
										
										<div v-if="essentialInformation.arrivalTime">
											<p>已到港 </p>
											<p v-if="essentialInformation.arrivalTime"> {{
													essentialInformation.arrivalTime.split(' ')[0] || '-'
											}}</p>
											<p v-else>-</p>
										</div>
										<div v-else-if="essentialInformation.expectArrivalTime">
											<p>预计到达时间 </p>
											<p v-if="essentialInformation.expectArrivalTime"> {{
													essentialInformation.expectArrivalTime.split(' ')[0] || '-'
											}}</p>
											<p v-else>-</p>
										</div>
									</div>
								</div> -->


							</div>
						</div>

						<div class="imgsa" :class="noScrollRight ? 'arrow' : 'arrow arrowOpacity'"
							@click="fnNext(activeName)">
							<img :src="arrowR" alt="" />
						</div>
					</div>
				</div>

			</div>
			<!-- <div class="nullimg" v-if="flags">
				<img src="../../assets/homePage/1662105414657.png" alt="">
			</div> -->
			<!-- 物流信息 -->

		</div>
	</div>

</template>

<script>
import axios from 'axios'
import {
	baseUrl
} from '../../tools/baseUrl';

import arrowL from "../../assets/homePage/leftArrow.png";
import arrowR from "../../assets/homePage/rightArrow.png"
import icon from "../../assets/homePage/placeOfShipment.png";
import ico3 from "../../assets/homePage/placeOfShipment1.png";
import icon1 from "../../assets/homePage/blueqizi.png";
import icon2 from "../../assets/homePage/huiqizi.png";
import xiehuo from '../../assets/homePage/xiehuo.png';
import iconArrow from "../../assets/homePage/departureAndArrival.png";
import search_Component from './search_Component.vue';
import mask_Component from './mask_Component.vue';
export default {
	name: "Server_Component",
	components: { search_Component, mask_Component },
	props: ['changeSercer'],
	data() {
		return {
			changeflag: true,
			bookingNo: "",
			value: "",
			flags: true,
			cargoTrackingList: [], //货运追踪
			cargoTrackingListLength: '',
			essentialInformation: [], //基本信息
			stepIndex: 1,
			left: 5,
			leftArrowL: false,
			rightArrowR: false,
			activeR: false,

			arrowL,
			arrowR,
			icon,
			ico3,
			icon1,
			icon2,
			iconArrow,
			xiehuo,
			currentProgressId: "",
			scrollResultWidth: 0, //transform滚动的距离
			signleWidth: 300, //单个流程的宽度
			activeName: 0,
			currentClickNumber: 0,
			noScrollRight: true,

			timer: null,
			frequency: 0,

			ri: 0,
		}
	},
	created() {
		if (this.left >= 4) {
			this.activeR = true
		};


	},
	mounted() {

	},
	methods: {
		inputNum1(e) { //限制输入数字
			this.bookingNo = e.target.value;
		},

		check_num: function () {
			// this.bookingNo = this.bookingNo.replace(/[^\a-\z\A-\Z0-9]/g, '');
			this.bookingNo = this.bookingNo.replace(/^\s+|\s+$/g, '');
			// this.bookingNo = this.bookingNo.replace(/[\u4e00-\u9fa5]/g, '');
		},
		serverBtn() {

			if (this.bookingNo == '') {
				this.$message({
					showClose: true,
					message: '请输入订舱号',
					type: 'warning'
				});
				return false
			} else {
				this.searchGet();
				this.searchGet()
			}

		},
		leftArrow() {
			// console.log("右滑动");

			// this.activeR = false;
			this.leftArrowL = true;
			this.rightArrowR = false


		},
		rightArrow() {
			this.rightArrowR = true;
			this.leftArrowL = false;
		},
		searchGet() {
			var token = sessionStorage.getItem("token");
			axios.get(`${baseUrl}/routering/search`, {
				params: {
					keyWords: this.bookingNo, //bookingNo 92022080910385657053
				},
				headers: {
					'authorization': token
				}
			})
				.then(res => {
					if (res.data.success == true) {
						this.$forceUpdate();//强制更新
						this.frequency++;
						this.cargoTrackingListLength = '';
						this.cargoTrackingList = [];
						console.log(res.data.result);
						this.essentialInformation = res.data.result.oderDetail;
						this.cargoTrackingList = res.data.result.routering;
						this.cargoTrackingList = this.cargoTrackingList.reverse();
						this.changeflag = false;
						this.timer = null;
						clearTimeout(this.timer)
						if (this.frequency == 1) {
							// let height = this.$refs[`centerSc`].clientWidth || null;;
							// console.log('3@#@', height);
							// this.timer = setTimeout(() => {
							// 	for (let i = 0; i <= ri; i++) {
							// 		this.fnNext();
							// 		console.log("$$$$$");
							// 	}
							// }, 1)

						} else {
							this.changeflag = true
							this.$nextTick(() => {
								this.changeflag = false;
								this.frequency = 0
							})
						}

						//判断内容超过一屏时：

						this.$nextTick(() => {
							setTimeout(() => {
								this.initgoRightArrow();
							});
						});
						// let ri = 0;
						this.ri = this.cargoTrackingList.length;
						let rs = this.ri
						this.cargoTrackingListLength = this.ri - 1;

						this.timer = setTimeout(() => {
							let heights = this.$refs[`centerSc`].clientWidth || null;
							let heights1 = this.$refs[`fixedBox`].clientWidth || null;
							if (heights >= heights1) {
								this.initgoRightArrow();
								for (let i = 0; i <= 30; i++) {
									// this.fnNext();
									this.fnPrev()
									// this.fnScrollWidth("reduce");
								}
								for (let i = 0; i <= this.ri; i++) {
									this.fnNext();
								}
								return
							} else {
								this.initgoRightArrow();
								for (let i = 0; i <= 30; i++) {
									// this.fnNext();
									this.fnPrev()
									// this.fnScrollWidth("reduce");
								}

							}
							// this.fnScrollWidth("add");
						}, 1)
					} else {
						let messages = res.data.message;
						this.$message({
							showClose: true,
							message: messages,
							type: 'warning'
						});
						return false
					}
				})
				.catch(err => {
					console.error(err);
				})
		},

		// ----------------
		//初始化判断是否可以向右滚动
		initgoRightArrow() {
			const currentScrollWidth = this.$refs[`fixedBox`].clientWidth || null;
			const canNumber = Math.floor(currentScrollWidth / this.signleWidth); //可以放下的个数
			//如果最后一个流程图标已经展示出来，则停止滚动
			if (this.currentClickNumber + canNumber >= this.cargoTrackingList.length + 1) {
				this.noScrollRight = false;
				return;
			}
		},
		//点击上一个
		fnPrev() {
			//如果右点击的次数大于0，才可以左滚
			if (this.currentClickNumber > 0) {
				this.currentClickNumber -= 1;
				this.noScrollRight = true;
				this.fnScrollWidth("reduce");
			} else {
				return false;
			}
		},
		//点击下一个
		fnNext(activeName) {
			const currentScrollWidth = this.$refs[`fixedBox`].clientWidth || null;

			const canNumber = Math.floor(currentScrollWidth / this.signleWidth); //可以放下的个数
			//如果最后一个流程图标已经展示出来，则停止滚动
			if (this.currentClickNumber + canNumber >= this.cargoTrackingList.length + 1) {
				return false;
			}
			//说明放不下有滚动条
			if (this.cargoTrackingList.length > canNumber) {
				this.currentClickNumber += 1;
				if (this.currentClickNumber + canNumber >= this.cargoTrackingList.length + 1) {
					this.noScrollRight = false;
				}
				this.fnScrollWidth("add");
			}
		},
		//translate的宽度
		fnScrollWidth(type) {
			let result = 0;
			if (type === "reduce") {
				result = 300;
			} else if (type === "add") {
				result = -300;
			} else {
				result = 0;
			}
			this.scrollResultWidth += result;
		},


	},

}
</script>
<style lang="scss">
.stepIcon {
	width: 34px;
	height: 34px;
	// background: #025291;


}

.build {
	background: url("../../assets/homePage/placeOfShipment.png");
	background-size: 34px;
}

.fahuodi {
	background: url("../../assets/homePage/placeOfShipment.png");
	background-size: 34px;
}

.qiyun {
	background: url("../../assets/homePage/shipsAnchor-N.png");
	background-size: 34px;
}

.xiayi {
	background: url("../../assets/homePage/shipsAnchor-N.png");
	background-size: 34px;
}

.mudi {
	background: url("../../assets/homePage/shipsAnchor-N.png");
	background-size: 34px;
}

.xiehuo {
	background: url("/assets/homePage/dischargeCargo-N.png");
	background-size: 34px;
}

.el-step {
	&>.is-success {
		.is-text {
			i {

				//默认成功状态是在这个伪类中写的，因此覆盖掉这个√，写入自己的图片
				&:before {
					content: "";
				}

				width:35px;
				height:35px;
				background-size: 100% 100%;
				background-image: url("../../assets/homePage/shipsAnchor.png");
			}
		}
	}

	.el-step__title {
		text-indent: 6px;
	}
}

.el-step__head.is-success {
	border-color: transparent;
}
</style>


<style lang="scss" scoped>
.activeRight {
	// right: 200px;
	animation: test_Right linear;
	animation-fill-mode: both;
}

.leftArrows {
	animation: test_Left linear;
	animation-fill-mode: both;
}

.rightArrows {
	animation: test_Right linear;
	animation-fill-mode: both;
}

@keyframes test_Left {

	100% {
		right: 0px;
		transform: translateX(0px);
	}
}

@keyframes test_Right {

	100% {
		transform: translateX(-200px);
	}
}

.box1 {
	width: 100%;
	height: 100%;
	background-color: #F6F6F6;
	overflow: hidden;
}

.server_box {
	width: 1200px;
	margin: 0 auto;
	// background-color: #F6F6F6;

	.servers {
		width: 1200px;
		margin-top: 54px;

		.server_Title {
			width: 224px;
			height: 60px;
			text-align: left;
			line-height: 60px;
			background: url('../../assets/homePage/searchTop.png') no-repeat;
			background-size: cover;

			img {
				width: 14px;
				margin-left: 64px;
				transform: translateY(25%);
			}

			span {
				font-size: 14px;
				font-weight: 400;
				color: #FFFFFF;
				line-height: 60px;
				margin-left: 5px;
			}
		}

		.server_Button {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 1200px;
			height: 94px;
			background: #FFFFFF;
			border-radius: 0px 16px 16px 16px;
			opacity: 1;
			border: 1px solid #999999;
			padding: 0 54px;

			// 出发站
			.setOut {
				width: 284px;
				height: 40px;
				background: #FFFFFF;
				border-radius: 4px 4px 4px 4px;
				opacity: 1;
				border: 1px solid #333333;
				overflow: hidden;

				.el-select {
					width: 100%;
					height: 100%;
					border: 0;
					border-radius: 4px 4px 4px 4px;

					::v-deep .el-input__inner {
						border: 0;
						font-size: 14px;
						font-weight: 400;
						color: #333333;
					}
				}
			}

			// 订舱号
			.bookingNo {
				width: 932px;
				height: 40px;
				background: #FFFFFF;
				border-radius: 4px 4px 4px 4px;
				opacity: 1;
				border: 1px solid #999999;
				overflow: hidden;

				input {
					width: 100%;
					height: 100%;
					border: 0;
					outline: none;
					padding-left: 16px;
					font-size: 14px;
					color: #999999;
				}
			}

			.btn {
				width: 120px;
				height: 40px;
				background: #FFC13B;
				box-shadow: 0px 3px 6px 1px rgba(255, 193, 59, 0.3);
				border-radius: 4px 4px 4px 4px;
				text-align: center;
				line-height: 40px;
				color: #2B2B2B;

				.el-button {
					width: 100%;
					height: 100%;
					border: 0;
					background-color: transparent;
					color: #2B2B2B;
				}
			}
		}
	}

	.nullimg {
		display: flex;
		width: 1200px;
		height: 335px;
		background: #FFFFFF;
		border-radius: 16px 16px 16px 16px;
		margin: 24px auto 0;

		img {
			width: 590px;
			height: 299px;
			margin: 18px auto;
		}
	}


}

// ------------------------============================
.logistics {
	width: 1200px;
	height: 660px;
	// border-radius: 16px 16px 16px 16px;
	border: 1px solid #999999;
	margin-top: 24px;
	background: #FFFFFF;

	.img {
		width: 590px;
		height: 299px;
		margin: 0 auto;
	}

	// 基本信息
	.essentialInformation {
		width: 1152px;


		margin: 32px auto 0;

		.basic_Title {
			width: 64px;
			height: 16px;
			font-size: 16px;
			font-family: Source Han Sans CN-Regular, Source Han Sans CN;
			font-weight: 400;
			color: #999999;
			line-height: 16px;
		}

		.basic_Content {
			position: relative;
			width: 1152px;
			display: flex;
			justify-content: space-around;
			// height: 218px;
			background: #F4F4F4;
			margin-top: 17px;
			font-size: 12px;
			// padding-top: 32px;
			padding-bottom: 32px;

			ul {
				margin-top: 30px;

				li {
					display: flex;
					margin-top: 14px;
					font-weight: 400;
					font-size: 12px;

					p {
						text-align: right;
						width: 123px;
						height: 12px;

						color: #999999;
					}

					span {

						color: #333333;
					}
				}

				li:nth-child(1) {
					margin-top: 0;
				}
			}
		}

		.basic_Content::before {
			position: absolute;
			bottom: -24px;
			content: '';
			width: 1152px;
			height: 0px;
			border-bottom: 1px dashed #999999;
		}
	}

	.logisticsInformation {
		margin-top: 56px;
		padding: 0 24px;

		.logistics_Title {
			font-size: 16px;
			font-weight: 400;
			color: #999999;
			line-height: 16px;
		}


		.mappings {
			position: relative;
			padding-top: 50px;
			white-space: nowrap;
			width: 100%;

			// overflow: hidden;

			// overflow-x: scroll;
			.mappings_Box {
				// position: absolute;
				position: relative;
				width: 4000px;
				// width: auto;
				margin-left: 40px;
				// transform: translateX(-200px);

				.img {
					position: absolute;
					top: -20px;
					// left: 220px;
					width: 24px;
					height: 18px;
					z-index: 100;
				}

				/*完成状态——横线颜色设置*/
				::v-deep .is-finish .el-step__line {
					background-color: #025291;
				}

				/*完成状态——圆圈边框颜色*/
				::v-deep .el-step__head.is-finish {
					border-color: transparent;
				}

				::v-deep .el-step__line {
					border-color: transparent;
				}

				/*等待状态——序号颜色、圆圈边框颜色*/
				::v-deep .el-step__head.is-process {
					border-color: transparent;
				}

				::v-deep .el-step__head.is-wait {
					border-color: transparent;
				}

				::v-deep .el-step__icon {
					width: 40px;
					height: 40px;
				}

				::v-deep .el-step.is-horizontal .el-step__line {
					top: 18px;
				}

				::v-deep .el-step__icon {
					// background: url('../../assets/订舱-立即下单/校验_check-one\ \(1\).png') no-repeat;
					background-size: cover;
					background-position: 0 -7px;
				}

				::v-deep .el-step__title {
					color: #999999;
					font-size: 14px;
					font-weight: 500;
				}

				::v-deep .el-step__description {
					color: #333333;
					font-size: 12px;
				}

				ul {
					display: flex;
					justify-content: space-around;
					text-align: center;
					margin-top: 24px;

					li {
						margin-top: 8px;
						font-size: 14px;
						font-weight: 500;
						color: #025291;
					}
				}
			}

			.leftArrow,
			.rightArrow {
				position: absolute;
				// top: 0;
				width: 28px;
				height: 28px;
				// background: #F4F4F4;
				// background: red;
				background: #F4F4F4;
				border-radius: 30px 30px 30px 30px;

				img {
					// display: block;
					// margin: auto;
					width: 18px;
					height: 19px;
					transform: translate(25%, 25%)
				}
			}

			.leftArrow {
				top: 50%;
				// background: url('../../assets/homePage/left.png') no-repeat;
				background: #F4F4F4;
			}

			.rightArrow {
				top: 50%;
				right: 0;
			}
		}


	}
}

.essentialInformation {
	width: 1152px;


	margin: 32px auto 0;

	.basic_Title {
		width: 64px;
		height: 16px;
		font-size: 16px;
		font-family: Source Han Sans CN-Regular, Source Han Sans CN;
		font-weight: 400;
		color: #999999;
		line-height: 16px;
	}

	.basic_Content {
		position: relative;
		width: 1152px;
		display: flex;
		justify-content: space-around;
		// height: 218px;
		background: #F4F4F4;
		margin-top: 17px;
		font-size: 12px;
		// padding-top: 32px;
		padding-bottom: 32px;

		ul {
			margin-top: 30px;

			li {
				display: flex;
				margin-top: 14px;
				font-weight: 400;
				font-size: 12px;

				p {
					text-align: right;
					width: 123px;
					height: 12px;

					color: #999999;
				}

				span {

					color: #333333;
				}
			}

			li:nth-child(1) {
				margin-top: 0;
			}
		}
	}

	.basic_Content::before {
		position: absolute;
		bottom: -24px;
		content: '';
		width: 1152px;
		height: 0px;
		border-bottom: 1px dashed #999999;
	}
}

.logistics_Title {
	font-size: 16px;
	font-weight: 400;
	color: #999999;
	line-height: 16px;
}

.singTitle1 {
	font-size: 14px;
	font-weight: 500;
	color: #999999;
}

.singTitle2 {
	font-size: 12px;
	font-weight: 400;
	color: #333333;
}

.ProgressBoxTool {
	width: 1200px;
	margin: 56px auto 0;
	padding: 0 24px;
}

//中间的时间发展部分
.processBox {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 48px auto;

	.imgsa {
		width: 28px;
		height: 28px;

		img {
			width: 100%;
			height: 100%;
		}
	}

	.arrow {
		width: 28px;
		height: 28px;
		cursor: pointer;
	}

	.arrowOpacity {
		cursor: default;
		opacity: 0.4;
	}

	.fixedBox {
		flex: 1;
		height: 200px;
		overflow: hidden;
	}

	.centerScroll {
		// flex: 1;
		box-sizing: border-box;
		white-space: nowrap;

		// width: calc(100% - 120px);
		// overflow-x: auto;
		.signleTab {
			width: 300px;
			position: relative;
			display: inline-block;
			font-size: 16px;

			.newimg {
				margin-bottom: 20px;
			}

			.singCon {
				position: absolute;
				margin-top: 24px;
				font-size: 14px;

				p:nth-child(1) {
					font-size: 14px;
					font-family: Source Han Sans CN-Medium, Source Han Sans CN;
					font-weight: 500;
					color: #025291;
				}

				p:nth-child(2) {
					font-size: 12px;
					font-family: Source Han Sans CN-Regular, Source Han Sans CN;
					font-weight: 400;
					color: #025291;
				}

			}

			.leftIcon {
				width: 48px;
				text-align: center;
				cursor: pointer;

				&>.pregressIcon {
					width: 40px;
					height: 40px;
				}
			}

			&>.arrowSquare {
				position: absolute;
				top: 20px;
				right: 32px;
				height: 2px;
				width: 200px;
				background-color: #999999;
			}

			.activearr {
				background-color: #025291;
			}

			.activearr1 {
				display: none;
			}

			.arrowSquare1 {
				position: absolute;
				top: 20px;
				right: 32px;
				height: 2px;
				width: 200px;
				background-color: #999999;
			}

			.arrowSquarea {
				position: absolute;
				top: 40px;
				right: 32px;
				height: 2px;
				width: 200px;
				background-color: #025291;
			}

			.arrowSquares {
				height: 2px;
				width: 200px;
				background-color: #025291;
				margin-top: 20px;
			}

			.shipImg {
				position: absolute;
				top: -20px;
				left: 50%;
				transform: translateX(-50%);
				width: 24px;
				height: 18px;

				img {
					width: 100%;
					height: 100%;
				}
			}

			.textNull {
				margin-top: 10px;
				text-align: center;

				p:nth-child(1) {
					font-size: 14px;
					font-weight: 500;
					color: #999999;
				}

				p:nth-child(2) {
					font-size: 12px;
					font-family: Source Han Sans CN-Regular, Source Han Sans CN;
					font-weight: 400;
					color: #333333;
				}

				.singCon {
					width: 100%;
					text-align: center;

					p:nth-child(1) {
						font-size: 14px;
						font-family: Source Han Sans CN-Medium, Source Han Sans CN;
						font-weight: 500;
						color: #025291;
					}

					p:nth-child(2) {
						font-size: 12px;
						font-family: Source Han Sans CN-Regular, Source Han Sans CN;
						font-weight: 400;
						color: #025291;
					}
				}
			}

			.arrowSquare {
				span::before {
					position: absolute;
					content: '';
					width: 10px;
					height: 10px;
					border-top: 2px solid #999999;
					border-right: 2px solid #999999;
					transform: rotate(45deg);
					right: -1px;
					top: -5px;
				}
			}

			.activearr {
				span::before {
					position: absolute;
					content: '';
					width: 10px;
					height: 10px;
					border-top: 2px solid #025291;
					border-right: 2px solid #025291;
					transform: rotate(45deg);
					right: -1px;
					top: -5px;
				}
			}

			.arrowSquare1 {
				span::before {
					position: absolute;
					content: '';
					width: 10px;
					height: 10px;
					border-top: 2px solid #999999;
					border-right: 2px solid #999999;
					transform: rotate(45deg);
					right: -1px;
					top: -5px;
				}
			}

			.arrowSquarea {
				span::before {
					position: absolute;
					content: '';
					width: 10px;
					height: 10px;
					border-top: 2px solid #025291;
					border-right: 2px solid #025291;
					transform: rotate(45deg);
					right: -1px;
					top: -5px;
				}
			}

			.newImg {
				position: absolute;
				top: -20px;
				left: 10px;
				width: 28px;
				height: 12px;

				// display: none;
				img {
					width: 100%;
					height: 100%;
				}
			}

		}

		.lisImg {
			position: relative;



		}
	}
}
</style>
