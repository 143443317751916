<template>
    <div class="box1">
        <Server_Component v-if="shows" :changeSercer="changeSercer"></Server_Component>
        <Footer_Compontent></Footer_Compontent>
    </div>
</template>
<!-- PersonalCenter -->
<script>
import Server_Component from '../../components/CargoTracking/Server_Component.vue'
import Footer_Compontent from '../../components/Footer_Compontent.vue'
export default {
    name: "",
    components: { Server_Component, Footer_Compontent },
    data() {
        return {
            shows: true
        }
    },
    methods: {
        changeSercer() {
            this.shows = false
            this.$nextTick(() => {
                this.shows = true;
                console.log("$$$");
            })
        }
    },
}
</script>

<style lang="scss" scoped>
.box1 {
    background-color: #f6f6f6;
}

.footer {
    margin-top: 153px;
}
</style>